import React from "react"
import { StaticQuery, graphql } from "gatsby"
import ReactDOM from "react-dom"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import * as _ from "lodash"
import breakpoints from "../utils/breakpoints"

const portalRoot =
  typeof document !== `undefined` ? document.getElementById("portal") : null

const OuterWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  min-height: 100%;
  overflow: visible;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  align-self: center;

  ${breakpoints[0]} {
    width: 80%;
  }

  ${breakpoints[1]} {
    width: 60%;
  }
`

const ImageWrapper = styled.div`
  transition: 0.2s;

  &.hidden {
    display: none;
  }

  .gatsby-image-wrapper {
    max-width: 100%;
    max-height: 100%;
  }
`

const Button = styled.button`
  position: fixed;
  z-index: 10001;

  &:focus,
  &:hover {
    // background: ${props => props.theme.colors.secondary};
  }
  
  &.navigation {
    right: 0px;
    top: auto;
    bottom: 0;
    font-size: 4rem;
    padding: 0;
    line-height: 1;
    font-weight: bold;
    padding: 0.7rem 1.3rem 0.5rem 1.3rem;
  }

  &.prev {
    left: 0px;
  }

  &.next {
    right: 0px;
  }
  

  ${breakpoints[0]} {
    &.navigation {
      top: 50%;
      bottom: auto;
      margin-top: -1.5rem;
    }
  }  
`

export default class Lightbox extends React.Component {
  constructor(props) {
    super(props)
    this.el =
      typeof document !== `undefined` ? document.createElement("div") : null

    this.state = {
      currentIndex: this.props.currentIndex,
      count: this.props.images.length,
    }
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    portalRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    portalRoot.removeChild(this.el)
  }

  next = e => {
    e.stopPropagation()

    this.setState((prevState, props) => ({
      currentIndex:
        prevState.currentIndex !== prevState.count - 1
          ? prevState.currentIndex + 1
          : 0,
    }))
  }

  previous = e => {
    e.stopPropagation()

    this.setState((prevState, props) => ({
      currentIndex:
        prevState.currentIndex !== 0
          ? prevState.currentIndex - 1
          : prevState.count,
    }))
  }

  close = () => {
    this.setState({
      showModal: false,
    })
  }

  render() {
    const { hideLightbox } = this.props

    if (this.el) {
      return ReactDOM.createPortal(
        <OuterWrapper onClick={() => hideLightbox()}>
          <Button
            onClick={() => hideLightbox()}
            style={{
              right: "0",
              top: "0",
              marginTop: 0,
            }}
          >
            Close
          </Button>

          <Button className="navigation prev"
            onClick={this.previous}
          >
            &lt;
          </Button>
          <Wrapper>
            <StaticQuery
              query={graphql`
                query {
                  allFile {
                    edges {
                      node {
                        relativePath
                        childImageSharp {
                          fluid(maxWidth: 1200) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }
                }
              `}
              render={data => {
                const { images } = this.props

                const { currentIndex } = this.state

                return images.map((screenshot, i) => {
                  const image = data.allFile.edges.find(edge => {
                    return edge.node.relativePath === screenshot.uri
                  })

                  if (!image) {
                    return null
                  }

                  return (
                    <ImageWrapper
                      key={i}
                      className={i === currentIndex ? "show" : "hidden"}
                    >
                      <Img fluid={image.node.childImageSharp.fluid} />
                    </ImageWrapper>
                  )
                })
              }}
            />
          </Wrapper>
          <Button className="navigation next"
            onClick={this.next}
          >
            &gt;
          </Button>
        </OuterWrapper>,
        this.el
      )
    } else {
      return null
    }
  }
}
