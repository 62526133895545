import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Screenshots from "../components/screenshots"
import Tags from "../components/tags"
import * as _ from 'lodash'
import breakpoints from "../utils/breakpoints"

import styled from "@emotion/styled"

const Article = styled.article`
  position: relative;
  padding: 0;
`

const H1 = styled.h1`
  // position: absolute;
  // background: rgba(255, 255, 255, 0.7);
  // text-indent: 1rem;
  // top: 0;
  // width: 100%;
  // height: 5rem;
  // margin: 0;
  // line-height: 5rem;
  // padding-left: 1rem;
  // padding-right: 1rem;

`

const Details = styled.p`
  // padding-left: 1rem;
  // padding-right: 1rem;
`

const H3 = styled.h3`
  // padding-left: 1rem;
  // padding-right: 1rem;
`

export default ({ data }) => {
  const { name, details, screenshot_set, tags } = data.jozzhartcom.project

  const filteredTags = _.filter(tags, tag => (tag.tag !== 'Back-end development' && tag.tag !== 'Front-end development' && tag.tag !== "Development") )

  return (
    <Layout>
      <SEO title={"Project - " + name} />
      <Article>
        <H1>{name}</H1>

        <Details dangerouslySetInnerHTML={{ __html: details }} />

        <H3>Tech</H3>
        <Tags tags={filteredTags} />

        <br />

        {(screenshot_set.length !==0) && <H3>Screenshots</H3>}
        <Screenshots screenshots={screenshot_set} />
      </Article>
    </Layout>
  )
}

export const query = graphql`
  query blah($id: Int!) {
    jozzhartcom {
      project(id: $id) {
        name
        details
        screenshot_set {
          uri
        }
        tags {
          tag
        }
      }
    }
  }
`