import React from "react"
import styled from "@emotion/styled"
import Swipe from "react-easy-swipe"

import Lightbox from "./lightbox"

import Screenshot from "./screenshot"

const Wrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
  padding: 0;
`

export default class Screenshots extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
      showLightbox: false,
      currentIndex: 0,
    }
  }

  showLightbox = index => {
    document.body.classList.add("modal-open")
    this.setState({
      showLightbox: true,
      currentIndex: index,
    })
  }

  hideLightbox = () => {
    document.body.classList.remove("modal-open")
    this.setState({
      showLightbox: false,
    })
  }

  render() {
    const { showLightbox, currentIndex } = this.state

    return (
      <Wrapper>
        {this.props.screenshots.map((screenshot, i) => (
          <Screenshot
            key={i}
            index={i}
            path={screenshot.uri}
            showLightbox={index => this.showLightbox(index)}
          ></Screenshot>
        ))}

        {showLightbox && (
          <Lightbox
            images={this.props.screenshots}
            currentIndex={currentIndex}
            hideLightbox={() => this.hideLightbox()}
          />
        )}
      </Wrapper>
    )
  }
}
