import React from "react"
import styled from "@emotion/styled"

import Drupal from "../svg/Drupal.svg";
import Angular from "../svg/Angular.svg";
import Airbnb from '../svg/Airbnb.svg';
import HTML5 from '../svg/HTML5.svg'
import Javascript from '../svg/Javascript.svg'
import Coldfusion from '../svg/Coldfusion.svg'
import ASP from '../svg/Asp.svg'
import Jquery from '../svg/Jquery.svg'
import Mongo from '../svg/Mongo.svg'
import Express from '../svg/Express.svg'
import GoogleMaps from '../svg/GoogleMaps.svg'
import Redis from '../svg/Redis.svg'
import Mocha from '../svg/Mocha.svg'
import Hapi from '../svg/Hapi.svg'
import Generic from '../svg/Generic.svg'
import Cordova from '../svg/Cordova.svg'
import Ionic from '../svg/Ionic.svg'
import Jest from '../svg/Jest.svg'
import Nodejs from '../svg/Nodejs.svg'
import Nightwatch from '../svg/Nightwatch.svg'
import Docker from '../svg/Docker.svg'
import Typescript from '../svg/Typescript.svg'
import Pa11y from '../svg/Pa11y.svg'
import Puppeteer from '../svg/Puppeteer.svg'
import Nunjucks from '../svg/Nunjucks.svg'
import Php from '../svg/Php.svg'
import Lumen from '../svg/Lumen.svg'
import Chai from '../svg/Chai.svg'
import Sass from '../svg/Sass.svg'
import Aws from '../svg/Aws.svg'
import Protractor from '../svg/Protractor.svg'
import breakpoints from "../utils/breakpoints"

const Tag = styled.li`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  font-size: 1.2rem;

  ${breakpoints[0]} {
    width: 50%;
  }

  ${breakpoints[1]} {
    width: 33%;
  }

  ${breakpoints[2]} {
    width: 25%;
  }

  svg {
    max-width: 2rem;
    max-height: 2rem;
    background: white;
    border-radius: 5px;
    padding: 5px;
  }

`

export default ({ tag }) => {
  let icon = <Generic />

  if (tag.tag === "Classic ASP") icon = <ASP />
  if (tag.tag === "HTML/CSS") icon = <HTML5 />
  if (tag.tag === "Javascript") icon = <Javascript />
  if (tag.tag === "Coldfusion") icon = <Coldfusion />
  if (tag.tag === "jQuery") icon = <Jquery />
  if (tag.tag === "MongoDB") icon = <Mongo />
  if (tag.tag === "Express") icon = <Express />
  if (tag.tag === "Google Maps APIs") icon = <GoogleMaps />
  if (tag.tag === "Redis") icon = <Redis />
  if (tag.tag === "Mocha") icon = <Mocha />
  if (tag.tag === "Angular") icon = <Angular />
  if (tag.tag === "hapi.js") icon = <Hapi />
  if (tag.tag === "Enzyme") icon = <Airbnb />
  if (tag.tag === "Cordova") icon = <Cordova />
  if (tag.tag === "Ionic") icon = <Ionic />
  if (tag.tag === "Drupal 6" || tag.tag === "Drupal 7") icon = <Drupal />
  if (tag.tag === "Jest") icon = <Jest />
  if (tag.tag === "Nodejs" || tag.tag === "Node.js") icon = <Nodejs />
  if (tag.tag === "Nightwatch.js") icon = <Nightwatch />
  if (tag.tag === "Docker") icon = <Docker />
  if (tag.tag === "Typescript") icon = <Typescript />
  if (tag.tag === "Pa11y") icon = <Pa11y />
  if (tag.tag === "Puppeteer") icon = <Puppeteer />
  if (tag.tag === "Nunjucks") icon = <Nunjucks />
  if (tag.tag === "PHP") icon = <Php />
  if (tag.tag === "Lumen") icon = <Lumen />
  if (tag.tag === "Chai") icon = <Chai />
  if (tag.tag === "SASS") icon = <Sass />
  if (tag.tag === "AWS") icon = <Aws />
  if (tag.tag === "S3") icon = <Aws />
  if (tag.tag === "Lambda") icon = <Aws />
  if (tag.tag === "DynamoDB") icon = <Aws />
  if (tag.tag === "Cognito") icon = <Aws />
  if (tag.tag === "Protractor") icon = <Protractor />


  return (
    <Tag>
      {icon} &nbsp; {tag.tag}
    </Tag>
  )
}
