import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import breakpoints from "../utils/breakpoints"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const ImageWrapper = styled.li`
  position: relative;
  overflow: hidden;
  width: 100%;
  a {
    display: block;
  }

  ${breakpoints[0]} {
    width: 50%;
  }

  ${breakpoints[3]} {
    width: 33.3%;
  }

  .gatsby-image-wrapper {
    margin: 0 1rem 2rem 1rem; 
    border-radius: 20px;

    -webkit-box-shadow: 0px 3px 4px 0px rgba(56,79,87,0.5);
    -moz-box-shadow: 0px 3px 4px 0px rgba(56,79,87,0.5);
    box-shadow: 0px 3px 4px 0px rgba(56,79,87,0.5);
    transition: box-shadow 0.1s ease-in-out;

    &:hover {
      -webkit-box-shadow: 0px 5px 6px 1px rgba(56,79,87,0.5);
      -moz-box-shadow: 0px 5px 6px 1px rgba(56,79,87,0.5);
      box-shadow: 0px 5px 6px 1px rgba(56,79,87,0.5);
    }
  }
`

const Image = ({ path, link, showLightbox, index, fluid }) => {

  if (fluid) {

    return (<ImageWrapper>
      {link && <Link to={link}>
        <Img fluid={fluid} />
      </Link>}
      {showLightbox &&
        <div onClick={() => showLightbox(index)} style={{
          cursor: "pointer"
        }}>
          <Img fluid={fluid} /></div>
      }

    </ImageWrapper>)

  } else {

    return <StaticQuery
      query={graphql`
      query {
        allFile {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 600, maxHeight: 337, cropFocus: NORTH ) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
          }
        }
      }
    `}
      render={data => {
        const image = data.allFile.edges.find(edge => {
          console.log('client image search')
          return edge.node.relativePath === path
        })
        if (!image) {
          return null
        }

        return (
          <ImageWrapper>
            {link && <Link to={link}>
              <Img fluid={image.node.childImageSharp.fluid} />
            </Link>}
            {showLightbox &&
              <div onClick={() => showLightbox(index)} style={{
                cursor: "pointer"
              }}>
                <Img fluid={image.node.childImageSharp.fluid} /></div>
            }

          </ImageWrapper>
        )
      }}
    />

  }
}
export default Image
