import React from "react"
import styled from "@emotion/styled"

import Tag from "./tag"

const Tags = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;

  padding-left: 0.5rem;
  padding-right: 0.5rem;
`

export default ({ tags }) => {
  return (
    <Tags>
      {tags.map((tag, i) => (
        <Tag key={i} tag={tag}></Tag>
      ))}
    </Tags>
  )
}
